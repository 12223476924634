import logo from './logo.svg';
import './App.css';
import { Resume } from './components/Resume';
import { ThemeProvider, createTheme } from '@mui/material/styles';



const THEME = createTheme({
  typography: {
   "fontFamily": `"Bebas Neue", "Helvetica", "Arial", sans-serif`,
   "fontSize": 14,
   "fontWeightLight": 300,
   "fontWeightRegular": 400,
   "fontWeightMedium": 500
  }
});

const App = () => {
  return(
  <ThemeProvider theme={THEME}>
   <Resume/>
  </ThemeProvider>
 );

  }

 export default App